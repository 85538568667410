import React from 'react'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import { Text } from '../../atoms'
import { Section } from '../../molecules'
import curveSvg from '../../../../static/curve.svg'
import homeSvg from '../../../../static/home.svg'
import familySvg from '../../../../static/family.svg'

const StyledGridContainer = styled(Grid)`
  display: flex;
  align-items: start;
`

const StyledGridItem = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Image = styled.img`
  margin-bottom: 32px;
`

const Feature = ({ svg, text, caption }) => (
  <StyledGridItem item md={4} sm={12}>
    <Image src={svg} alt={text} />
    <Text weight="bold" variant="h6">{text}</Text>
    <Text bottom={0} align="center" variant="h6">{caption}</Text>
  </StyledGridItem>
)

const Features = () => {
  return (
    <Section backgroundColor="#f6f6f6" maxWidth="md">
      <StyledGridContainer container spacing={6}>
        <Feature
          svg={curveSvg}
          text="Qualidade e experiência"
          caption="A cada atendimento, utilizamos novo filtro bacteriológico, priorizando sua saúde e o seu bem estar."
        />
        <Feature
          svg={familySvg}
          text="Atendemos crianças"
          caption="Com um atendimento lúdico, avaliamos crianças a partir dos 3 anos de idade até a fase adulta."
        />
        <Feature
          svg={homeSvg}
          text="Residência ou consultório"
          caption="Realizamos o exame em sua residência ou, se preferir, em nosso consultório."
        />
      </StyledGridContainer>
    </Section>
  )
}

export default Features
