import React from 'react'
import { Helmet } from 'react-helmet'
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from '../config/theme'
import {
  Header,
  Exam,
  Features,
  AboutUs,
  Location,
  ContactUs,
  References,
  Footer,
  Developer,
} from '../components/sections'
import seoBannerFile from '../../static/seo-banner.jpg'

const seo = {
  title: 'Espirair - Espirometria Pediátrica e Adulta',
  description:
    'Espirometria em crianças a partir de 3 anos de idade e adultos, com qualidade e experiência. Atendemos em domicílio e no consultório.',
  keywords: ['espirair', 'espirometria', 'espirometria pediátrica', 'fisioterapia'],
  url: 'https://www.espirair.com.br',
}

// NOTE: Main site color is #0a62d5.
const Home = () => {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="title" content={seo.title} />
        <meta name="description" content={seo.description} />
        <meta name="keywords" content={seo.keywords.join(', ')} />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <meta property="og:title" content={seo.title} />
        <meta property="og:image" content={seoBannerFile} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seo.url} />
        <meta name="theme-color" content="#0a62d5" />
      </Helmet>
      <CssBaseline />
      <Header />
      <Exam />
      <Features />
      <AboutUs />
      <Location />
      <ContactUs />
      <References />
      <Footer />
      <Developer />
    </ThemeProvider>
  )
}

export default Home
