import React from 'react'
import styled from 'styled-components'
import { Container } from '@material-ui/core'

const StyledSectionContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: ${({ $verticalPadding }) => $verticalPadding}px;
  padding-bottom: ${({ $verticalPadding }) => $verticalPadding}px;
`

const SectionContainer = ({ verticalPadding = 36, children, ...props }) => (
  <StyledSectionContainer $verticalPadding={verticalPadding} {...props}>
    {children}
  </StyledSectionContainer>
)

export default SectionContainer
