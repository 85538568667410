import React from 'react'
import styled from 'styled-components'
import { Link } from '@material-ui/core'
import { ArrowRightRounded, OpenInNew } from '@material-ui/icons'
import { Text } from '../../atoms'
import { Section } from '../../molecules'
import papers from './papers'

const Item = styled.div`
  display: flex;
`

const LinkIcon = styled(OpenInNew)`
  font-size: 14px;
  vertical-align: middle;
`

const Content = styled.div``

const Paper = ({ paper }) => (
  <Item>
    <ArrowRightRounded />
    <Link href={paper.link} color="inherit" target="_blank" rel="noreferrer">
      <Text>
        {paper.title} <LinkIcon />
      </Text>
    </Link>
  </Item>
)

const renderPapers = () =>
  papers.filter(paper => paper.isAuthor).map((paper, i) => <Paper paper={paper} key={i} />)

const renderOtherReferences = () =>
  papers.filter(paper => !paper.isAuthor).map((paper, i) => <Paper paper={paper} key={i} />)

const References = () => (
  <Section backgroundColor="#f6f6f6" maxWidth="md">
    <Content>
      <Text variant="h4">Publicações</Text>
      {renderPapers()}
      <Text variant="h6">Outras referências</Text>
      {renderOtherReferences()}
    </Content>
  </Section>
)

export default References
