import React from 'react'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'

const StyledText = styled(Typography)`
  ${({ $italic }) => $italic && 'font-style: italic;'}
  ${({ $weight }) => $weight && `font-weight: ${$weight};`}
  ${({ $align }) => $align && `text-align: ${$align};`}
  ${({ $color }) => $color && `color: ${$color};`}
  ${({ $bottom }) => `margin-bottom: ${$bottom}px;`}
`

const Text = ({ italic, weight, align, color, bottom = 16, ...props }) => (
  <StyledText
    $italic={italic}
    $weight={weight}
    $align={align}
    $bottom={bottom}
    $color={color}
    {...props}
  />
)

export default Text
