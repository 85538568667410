import React from 'react'
import { Container } from '@material-ui/core'
import { WhatsAppButton } from '../../molecules'
import styled from 'styled-components'
import bannerFile from '../../../../static/banner.png'
import logoFile from '../../../../static/logo-title.svg'

const HeaderArea = styled.header`
  background-image: url(${bannerFile});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 90% top;
`

const WideMain = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 700px;
`

const Main = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const LogoContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
`

const Logo = styled.img`
  padding: 16px;
  height: 400px;

  @media (max-width: 1024px) {
    height: 300px;
  }

  @media (max-width: 640px) {
    height: 200px;
  }

  @media (max-width: 320px) {
    height: 150px;
  }
`

const Header = () => (
  <HeaderArea>
    <WideMain>
      <Main maxWidth="md">
        <LogoContainer>
          <Logo src={logoFile} alt="Espirair - Espirometria Pediátrica e Adulta" />
        </LogoContainer>
        <WhatsAppButton />
      </Main>
    </WideMain>
  </HeaderArea>
)

export default Header
