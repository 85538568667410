import React from 'react'
import { Section, WhatsAppButton } from '../../molecules'

const ContactUs = () => (
  <Section maxWidth="md">
    <WhatsAppButton />
  </Section>
)

export default ContactUs
