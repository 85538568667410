import React from 'react'
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'

const StyledButton = styled(Button)`
  background-color: #4caf50;
  &:hover {
    background-color: #4caf50;
  }
`

const WhatsAppButton = () => (
  <StyledButton
    href={process.env.GATSBY_WHATSAPP_WEB_URL}
    target="_blank"
    variant="contained"
    color="primary"
    size="large"
    startIcon={<WhatsAppIcon />}
  >
    Entre em contato
  </StyledButton>
)

export default WhatsAppButton
