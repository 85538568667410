import React from 'react'
import styled, { css } from 'styled-components'
import { Grid, IconButton } from '@material-ui/core'
import { Facebook, WhatsApp, Instagram } from '@material-ui/icons'
import { Text } from '../../atoms'
import { Section } from '../../molecules'

const FACEBOOK_URL = process.env.GATSBY_FACEBOOK_URL
const WHATSAPP_WEB_URL = process.env.GATSBY_WHATSAPP_WEB_URL
const INSTAGRAM_URL = process.env.GATSBY_INSTAGRAM_URL

const iconStyle = css`
  font-size: 40px;
  color: #f6f6f6;
`

const FacebookIcon = styled(Facebook)`
  ${iconStyle}
`

const WhatsAppIcon = styled(WhatsApp)`
  ${iconStyle}
`

const InstagramIcon = styled(Instagram)`
  ${iconStyle}
`

const ContainerGrid = styled(Grid)`
  display: flex;
  align-items: center;
`

const LeftGrid = styled(Grid)``

const RightGrid = styled(Grid)`
  text-align: right;
`

const Footer = () => (
  <Section backgroundColor="#0a62d5" maxWidth="md">
    <ContainerGrid container spacing={3}>
      <LeftGrid item sm={8}>
        <Text color="#f6f6f6" weight="bold" bottom={0}>
          Espirair - Espirometria Pediátrica e Adulta
        </Text>
        <Text color="#f6f6f6" bottom={0}>
          Todos os direitos reservados - {new Date().getFullYear()}
        </Text>
      </LeftGrid>
      <RightGrid item sm={4}>
        <IconButton href={WHATSAPP_WEB_URL} target="_blank" aria-label="whatsapp">
          <WhatsAppIcon />
        </IconButton>
        <IconButton href={FACEBOOK_URL} target="_blank" aria-label="facebook">
          <FacebookIcon />
        </IconButton>
        <IconButton href={INSTAGRAM_URL} target="_blank" aria-label="instagram">
          <InstagramIcon />
        </IconButton>
      </RightGrid>
    </ContainerGrid>
  </Section>
)

export default Footer
