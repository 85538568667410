import React from 'react'
import styled from 'styled-components'
import { Grid, Typography } from '@material-ui/core'
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded'
import { Section } from '../../molecules'
import { Text } from '../../atoms'
import examFile from '../../../../static/exam.jpg'

const Item = styled.div`
  display: flex;
`

const Image = styled.img`
  max-width: 100%;
  max-height: 400px;
  border-radius: 5%;
`

const StyledGrid = styled(Grid)`
  display: flex;
  align-items: center;
`

const renderExamItems = () =>
  [
    'Algumas medicações poderão ser suspensas, de acordo com a solicitação médica.',
    'No dia do exame, não realizar exercícios intensos, e, no caso de adultos, não fumar.',
    'Usar roupas confortáveis que não dificultem a expansão do tórax e do abdômen.',
    'O paciente deve se alimentar, evitando ingerir alimentos ou bebidas de cor escura, tais como chocolate, refrigerante, chá e café.',
  ].map((item, i) => (
    <Item key={i}>
      <ArrowRightRoundedIcon />
      <Typography>{item}</Typography>
    </Item>
  ))

const Exam = () => {
  return (
    <Section maxWidth="md">
      <StyledGrid container spacing={3}>
        <Grid item md={7} sm={12}>
          <Text variant="h4">Sobre o exame</Text>
          <Text variant="body1" component="p">
            A espirometria é um exame que avalia o funcionamento dos pulmões. Utilizando jogos
            lúdicos, o paciente executa manobras de inspiração e expiração que possibilitam a
            análise dos fluxos e volumes pulmonares.
          </Text>
          <Text variant="body1" component="p">
            O equipamento utilizado no exame é calibrado diariamente, de acordo com a American
            Thoracic Society, para possibilitar resultados com maior acurácia e precisão.
          </Text>
          <Text variant="body1" component="p">
            As normas da Official American Thoracic Society e da European Respiratory Society
            Technical Statement são utilizadas para a realização do exame e emissão dos laudos.
          </Text>
          <Text variant="h6">Preparo para o exame</Text>
          {renderExamItems()}
        </Grid>
        <Grid item md={5} sm={12}>
          <Image src={examFile} />
        </Grid>
      </StyledGrid>
    </Section>
  )
}

export default Exam
