import React from 'react'
import { Link } from '@material-ui/core'
import { Text } from '../../atoms'
import { Section } from '../../molecules'

const GATSBY_DEVELOPER_URL = process.env.GATSBY_DEVELOPER_URL

const Developer = () => (
  <Section backgroundColor="#222222" verticalPadding={4} maxWidth="md">
    <Text color="#f6f6f6" variant="caption" bottom={0}>
      <Link href={GATSBY_DEVELOPER_URL} target="_blank" color="inherit">
        Desenvolvido por Rodrigo Soares
      </Link>
    </Text>
  </Section>
)

export default Developer
