import React from 'react'
import styled from 'styled-components'

const Background = styled.div`
  background-color: ${props => props.color};
  ${props => props.image && `
    background-image: url(${props.image});
    background-repeat: no-repeat;
    background-size: cover;
  `}
`

const SectionBackground = ({ color = '#ffffff', image, children }) => (
  <Background color={color} image={image}>
    {children}
  </Background>
)

export default SectionBackground
