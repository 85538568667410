import React from 'react'
import styled from 'styled-components'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { Text } from '../../atoms'
import { Section } from '../../molecules'
import mapFile from '../../../../static/map.jpg'

const PlaceIcon = styled(LocationOnIcon)`
  font-size: 75px;
`

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #f6f6f6;
  width: 60%;
`

const Location = () => (
  <Section backgroundImage={mapFile} maxWidth="md">
    <CenteredDiv>
      <PlaceIcon />
      <Text italic align="center" weight="bold" variant="h5">
        Atendemos na Zona Sul, na Barra da Tijuca e em Niterói.
      </Text>
    </CenteredDiv>
  </Section>
)

export default Location
