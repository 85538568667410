import React from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import { Text } from '../../atoms'
import { Section } from '../../molecules'
import aboutUsFile from '../../../../static/about-us.jpg'

const StyledGrid = styled(Grid)`
  display: flex;
  align-items: center;
`

const Image = styled.img`
  max-width: 100%;
  max-height: 400px;
  border-radius: 5%;
`

const Member = styled.div`
  margin-bottom: 16px;
`

const Name = props => (
  <Text variant="h6" component="span">
    {props.children}
  </Text>
)

const Description = props => (
  <Text variant="body1">
    {props.children}
  </Text>
)

const AboutUs = () => {
  return (
    <Section maxWidth="md">
      <StyledGrid container spacing={3}>
        <Grid item md={5} sm={12}>
          <Image src={aboutUsFile} />
        </Grid>
        <Grid item md={7} sm={12}>
          <Text variant="h4">Nossa equipe</Text>
          <Member>
            <Name>Sandra Lisboa</Name>
            <Description>
              Doutora em Ciência da Pós-Graduação em Clínica Médica pela UFRJ.
            </Description>
          </Member>
          <Member>
            <Name>Shandra Lisboa</Name>
            <Description>Médica pela Fundação Técnica Educacional Souza Marques.</Description>
          </Member>
          <Member>
            <Name>Marcio Monteiro</Name>
            <Description>Especialista em Fisioterapia Pneumofuncional e Terapia Intensiva.</Description>
          </Member>
          <Member>
            <Name>Izabela Sad</Name>
            <Description>Pneumologista, Doutora em Pediatria pelo IFF/Fiocruz.</Description>
          </Member>
        </Grid>
      </StyledGrid>
    </Section>
  )
}

export default AboutUs
