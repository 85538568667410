import React from 'react'
import { SectionBackground, SectionContainer } from '../../atoms'

const Section = ({ backgroundColor, backgroundImage, children, ...props }) => (
  <SectionBackground color={backgroundColor} image={backgroundImage}>
    <SectionContainer {...props}>{children}</SectionContainer>
  </SectionBackground>
)

export default Section
