export default [
  {
    title:
      'Utilização da porcentagem e escore Z para avaliar resposta broncodilatadora em crianças asmáticas de idade pré-escolar',
    link: 'http://aaai-asbai.org.br/detalhe_artigo.asp?id=1086',
    isAuthor: true,
  },
  {
    title:
      'Gravidade das doenças respiratórias de crianças e adolescentes através do VEF1: percentual predito versus z-escore',
    link: 'http://www.periodicoseletronicos.ufma.br/index.php/revistahuufma/article/view/11038',
    isAuthor: true,
  },
  {
    title:
      'Associação entre os sintomas respiratórios e broncoespasmo induzida pelo exercício em crianças e adolescentes asmáticas',
    link: 'https://www.amrigs.org.br/assets/images/upload/pdf/jornal/1565724889.pdf',
    isAuthor: true,
  },
  {
    title:
      'Estratégias interpretativas para diagnóstico de obstrução pulmonar através da espirometria, segundo os consensos ATS/ERS, SBPT e GINA, em asmáticos pediátricos',
    link: 'http://www.rmmg.org/artigo/detalhes/2476',
    isAuthor: true,
  },
  {
    title:
      'Associação entre fração exalada de óxido nítrico e dados da espirometria e o controle clínico da asma em crianças e adolescentes',
    link: 'https://www.scielo.br/scielo.php?script=sci_arttext&pid=S0103-05822018000100017',
    isAuthor: true,
  },
  {
    title: 'Qualidade da espirometria em crianças e adolescentes.',
    link:
      'http://rbm.org.br/details/185/pt-BR/qualidade-da-espirometria-em-criancas-e-adolescentes',
    isAuthor: true,
  },
  {
    title:
      'Manifestações respiratórias na doença de Pompe de início tardio: uma série de casos no Brasil',
    link:
      'https://www.jornaldepneumologia.com.br/details/2640/pt-BR/manifestacoes-respiratorias-na-doenca-de-pompe-de-inicio-tardio--uma-serie-de-casos-no-brasil',
    isAuthor: true,
  },
  {
    title:
      'Utilização do limite inferior da normalidade versus ponto de corte fixo na avaliação de crianças e adolescentes com doenças pulmonares',
    link: 'https://www.amrigs.org.br/assets/images/upload/pdf/jornal/1652473363.pdf',
    isAuthor: true,
  },
  {
    title: 'Protocolo ATS 2019',
    link: 'https://www.atsjournals.org/doi/full/10.1164/rccm.201908-1590ST',
    isAuthor: false,
  },
  {
    title: 'Protocolo ATS 2007 (Pré-escolar)',
    link: 'https://www.thoracic.org/statements/resources/pldd/pft-in-children.pdf',
    isAuthor: false,
  },
  {
    title: 'Espirometria em idade pré-escolar na prática clínica',
    link: 'http://www.scielo.mec.pt/scielo.php?script=sci_arttext&pid=S0871-97212012000100003',
    isAuthor: false,
  },
  {
    title:
      'Avaliação da função respiratória: comparação entre valores de referência percentuais fixos e o 5º percentil para diagnóstico de obstrução das vias aéreas',
    link: 'http://aaai-asbai.org.br/detalhe_artigo.asp?id=647',
    isAuthor: false,
  },
]
